.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #333;
  color: #fff;
  position: sticky; /* Make the header sticky */
  top: 0; /* Stick to the top of the viewport */
  z-index: 1000; /* Ensure the header is above other content */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: add shadow for depth */
}

.logo img {
  max-width: 150px;
  height: 50px;
}

.nav-container {
  display: flex;
  align-items: center;
}

.nav-item {
  list-style: none;
  display: flex;
  margin: 0;
}

.nav-item li {
  margin: 0 15px;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s;
}

.nav-links:hover {
  color: rgb(170, 170, 170); /* Change color on hover */
  cursor: pointer;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  height: 3px;
  width: 25px;
  background-color: #fff;
  margin: 4px 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav-container {
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #333;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    flex-direction: column;
  }

  .nav-container.active {
    max-height: 300px; /* Adjust according to the number of items */
  }

  .nav-item {
    flex-direction: column;
    align-items: center;
  }

  .hamburger {
    display: flex;
  }
}
