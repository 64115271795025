/* Global styles */
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  background-color: #f4f4f4;
  color: #333;
  scroll-behavior: smooth; /* Smooth scrolling */
}

h1,
h2,
h3 {
  color: #333;
  font-family: "Roboto", sans-serif;
}

a {
  color: #1a73e8;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
}

/* Main container style */
#home,
#about,
#comedy,
#editing,
#articles {
  padding: 60px 0;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

footer {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
}

footer a {
  color: white;
  margin: 0 10px;
}
